import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {POWER} from "../constant";

const root_path = 'https://solarviewuser.tncross.co.jp';
const root_template_path = root_path + '/templates';
const image_path = 'images';
const timer = 300;

function Template() {
  let [facilityData, setFacilityData] = useState(null);
  let [facilityLayout, setFacilityLayout] = useState(null);
  let [showTemplate, setTemplate] = useState('template');
  let [cssLayout, setCssLayout] = useState('');
  let templateCss = null;
  let facilityById = null;
  let chartCss = null;
  let energyExplainCss = null;
  
  const GetElementById = (id) => {
    return document.getElementById(id);
  };
  
  const changeTemplate = () => {
    if (showTemplate === 'template') {
      showTemplate = 'chart';
    } else if (showTemplate === 'chart') {
      showTemplate = 'energy-explain';
    } else if (showTemplate === 'energy-explain') {
      showTemplate = 'template';
    }
    
    facilityById = facilityById || GetElementById('facility');
    templateCss = templateCss || GetElementById('tab-template');
    chartCss = chartCss || GetElementById('tab-chart');
    energyExplainCss = energyExplainCss || GetElementById('tab-energy-explain');
    
    if (templateCss && chartCss) {
      
      setTemplate(showTemplate);
      
      chartCss = chartCss || GetElementById('tab-chart');
      if (showTemplate === 'template') {
        templateCss.classList.remove('d-none');
        chartCss.classList.add('d-none');
        energyExplainCss.classList.add('d-none');
      }
      else if (showTemplate === 'chart') {
        chartCss.classList.remove('d-none');
        templateCss.classList.add('d-none');
        energyExplainCss.classList.add('d-none');
      }
      else if (showTemplate === 'energy-explain') {
        energyExplainCss.classList.remove('d-none');
        templateCss.classList.add('d-none');
        chartCss.classList.add('d-none');
      }
    }
  };
  
  const onChangeTemplate = () => {
    changeTemplate();
  };
  const facility_url = '26d0903dma';
  const template_path = facility_url;
  document.body.style.background = '#000000';
  // const template_path = ['school', 'culture-house', '26d0903dma'].includes(facility_url) ? facility_url : 'default';
  
  const reloadTemplate = () => {
    let getFacilityId = null;
    let getTemplateId = null;
    let set_measurement_date = '';
    let set_power_generation_total;
    const interval = setInterval(() => {
      getFacilityId = getFacilityId || GetElementById(facility_url);
      getTemplateId = getTemplateId || GetElementById('template');
      if (getFacilityId) {
        getTemplateId.classList.remove('d-none');
        clearInterval(interval);
      }
    }, 1000);
    
    axios.all([
      axios.get(root_path + '/api/facility_data/' + facility_url),
      axios.get(root_path + '/api/facility_layout/' + facility_url),
      axios.get(root_path + '/api/facility_chart_data/' + facility_url),
    ]).then(axios.spread((res_data, res_layout, res_chart) => {
      
      setFacilityData(res_data.data);
      setFacilityLayout(res_layout.data);
      if (res_data.data) {
        let {facility, html} = res_layout.data;
        const {data, actualData} = res_data.data;
        const facility_data = res_data.data.facility;
        html = facility.ruby === 1 ? html[template_path].yes_ruby : html[template_path].no_ruby;
        html = html.replace(/<head>(?:.|\n|\r)+?<\/head>/m, '');
        
        // Only school and culture-house
        // const formatKeys = ['power_generation', 'amount_of_electricity_sold', 'power_purchase',
        //   'power_generation_total', 'storage_amount', 'latest_renewable_energy_usage_rate'
        // ];
        // formatKeys.forEach(key => {
        //   data[key] = data[key] || 0;
        // });
        
        let {
          power_generation, power_purchase, amount_of_electricity_sold, total_consumption,
          charge_amount_child_meter, measurement_date,
          storage_amount, latest_renewable_energy_usage_rate,
          current_power_generation_rate, CO2_reduction_amount, CO2_absorption_by_tree
        } = data;
        let {
          power_generation_total
        } = facility_data;
        
        set_power_generation_total = power_generation_total;
        
        //Safari only run yyyy/mm/dd hh:mm:sss
        const actual_measurement_date = actualData.measurement_date ?? '';
        measurement_date = measurement_date ? new Date(measurement_date.replaceAll("-", "/")) : new Date(new Date().toLocaleString({ timeZone: 'Asia/Tokyo' }));
        const year = String(measurement_date.getFullYear());
        const month = String(measurement_date.getMonth() + 1).padStart(2, '0');
        const date = String(measurement_date.getDate()).padStart(2, '0');
        const hours = String(measurement_date.getHours()).padStart(2, '0');
        // const minutes = String(measurement_date.getMinutes()).padStart(2, '0');
        const day = measurement_date.getDay();
        const days_ja = ['日', '	月', '火', '水', '木', '金', '土'];// cn, t2, t3, t4, t5,t6, t7
        
        set_measurement_date = `${month}月${date}日(${days_ja[day]}) ${hours}:00`;
        const measurement_date_data = `${year}-${month}-${date} ${hours}:00:00`;

        // css = testCss();
        // html = testHtml();
        html = html.replace(/.\/images/g, `${root_template_path}/${template_path}/${image_path}`);
        // css = css ? css.replace(/.\/images/g, `${root_template_path}/${template_path}/${image_path}`): css

        // "measurement_date": "データ計測_日時",
        html = html.replace('{actual_measurement_date_data}', actual_measurement_date);
        html = html.replace('{measurement_date_data}', measurement_date_data);
        html = html.replace('{measurement_date}', set_measurement_date);
        html = html.replace('{current_power_generation_rate}', current_power_generation_rate || (current_power_generation_rate === 0 ? 0 : '--'));
        html = html.replace('{CO2_reduction_amount}', CO2_reduction_amount || (CO2_reduction_amount === 0 ? 0 : '--'));
        html = html.replace('{CO2_absorption_by_tree}', CO2_absorption_by_tree || (CO2_absorption_by_tree === 0 ? 0 : '--'));
        
        // Set background_image and logo when have url
        const intervalImage = setInterval(() => {
          console.log('Interval 0');
          getFacilityId = getFacilityId || GetElementById(template_path);
          if (getFacilityId) {
            const {background_image, character_image, name, ruby} = facility;
            const img_logo = GetElementById('logo');

            if (background_image) {
              getFacilityId.style.backgroundImage = `url(${background_image})`;
            }
            else if (template_path === 'default' && name) {
              if (name.search('民館') > -1) { // 民館 (culture house)
                const image_name = ruby ? 'culture-house-yes-ruby.png' : 'culture-house-no-ruby.png';
                getFacilityId.style.backgroundImage = `url(${root_template_path}/culture-house/${image_path}/${image_name})`;
              }
              else if (name.search('学校') > -1) { //学校 (school)
                const image_name = ruby ? 'school-yes-ruby.png' : 'school-no-ruby.png ';
                getFacilityId.style.backgroundImage = `url(${root_template_path}/school/${image_path}/${image_name})`;
              }
            }
            else if (template_path === '26d0903dma') {
              const image_name = ruby ? 'hall-yes-ruby.png' : 'hall-no-ruby.png ';
              getFacilityId.style.backgroundImage = `url(${root_template_path}/26d0903dma/${image_path}/${image_name})`;
              if (img_logo) {
                img_logo.src = `${root_template_path}/26d0903dma/${image_path}/nashi.gif`;
              }
            }
            
            if (img_logo && character_image)
              img_logo.src = character_image;
            
            clearInterval(intervalImage);
          }
        }, timer);
        
        // facility_name
        const facility_name = facility.name || '';
        html = html.replace('{facility_name}', facility_name);
        
        //1. power_generation: 発電量_kWh}, status: なし, 発電中
        // power_generation_total: 発電量_total_kW
        const set_power_generation = power_generation ? power_generation.toFixed(1) : (power_generation === 0 ? 0 : '--');
        html = html.replace('{発電量_kWh}', set_power_generation);
        html = html.replace('{power_generation_total}', Math.ceil(power_generation_total));
        
        if (parseInt(set_power_generation) >= 1) {
          html = html.replace('{発電量_status}', POWER.status[1]);
        }
        else {
          html = html.replace('{発電量_status}', POWER.status[0]);
          
          const interval = setInterval(() => {
            console.log('Interval 1');
            getFacilityId = getFacilityId || GetElementById(template_path);
            if (getFacilityId) {
              const img_sun = GetElementById('img-sun');
              if (img_sun) img_sun.classList.add("d-none");
              
              const img_yellow_arrow = GetElementById('img-yellow-arrow');
              if (img_yellow_arrow) img_yellow_arrow.classList.add("d-none");
              
              const img_green_arrow = GetElementById('img-green-arrow');
              if (img_green_arrow) img_green_arrow.classList.add("d-none");

              const power_generation_available = GetElementById('power_generation_available');
              if (power_generation_available) power_generation_available.classList.add("d-none");

              const power_generation_zero = GetElementById('power_generation_zero');
              if (power_generation_zero) power_generation_zero.classList.remove("d-none");
              
              
              const none = GetElementById('発電量_none');
              if (none) none.style.color = 'transparent';
              
              clearInterval(interval);
            }
          }, timer);
        }
        
        //2. power_purchase: 買電量_kWh} , status: なし, 発電中
        const set_power_purchase = power_purchase ? power_purchase.toFixed(1) : (power_purchase === 0 ? 0 : '--');
        html = html.replace('{買電量_kWh}', set_power_purchase);
        
        if (parseInt(set_power_purchase) >= 1) {
          html = html.replace('{買電量_status}', POWER.status[2]);
        }
        else {
          html = html.replace('{買電量_status}', POWER.status[0]);
          
          const interval = setInterval(() => {
            console.log('Interval 2');
            getFacilityId = getFacilityId || GetElementById(template_path);
            if (getFacilityId) {
              // const img_brown_arrow = GetElementById('img-brown-arrow');
              // if (img_brown_arrow) img_brown_arrow.classList.add("d-none");
              
              const none = GetElementById('買電量_none');
              if (none) none.style.color = 'transparent';
              
              clearInterval(interval);
            }
          }, timer);
        }
        
        //3. amount_of_electricity_sold: 売電量_kWh}, status: 送電中, なし, "charge_amount_child_meter": "充電量_kWh}_子メータ",
        //充電量_%_子メータ = ”放電可能量_kW” ∕（”充電可能量_kW”＋”放電可能量_kW”）
        //”放電可能量_kW" ＝「bat-01_放電可能量_kW〜bat-06_放電可能量_kW」( = bat_01_discharge_able_amount)
        //”充電可能量_kW” =「bat-01_充電可能量_kW〜bat-06_充電可能量_kW」 (= bat_01_chargeable_amount)
        const bat_amount = Math.ceil(storage_amount ?? 0);
        html = html.replace('{充電量_%_子メータ}', bat_amount + '%');
        
        if (bat_amount === 100) { //100%
          const interval = setInterval(() => {
            console.log('Interval battery');
            getFacilityId = getFacilityId || GetElementById(template_path);
            if (getFacilityId) {
              const img_battery = GetElementById('img-battery');
              if (img_battery) img_battery.src = `${root_template_path}/${template_path}/${image_path}/battery04.png`;
              
              clearInterval(interval);
            }
          }, timer);
        }
        
        charge_amount_child_meter = charge_amount_child_meter ? charge_amount_child_meter.toFixed(1) : (charge_amount_child_meter === 0 ? 0 : '--');
        html = html.replace('{充電量_kWh_子メータ}', charge_amount_child_meter);
        
        if (parseInt(charge_amount_child_meter) >= 1) {
          html = html.replace('{充電量_status_子メータ}', POWER.status[3]);
        }
        else {
          html = html.replace('{充電量_status_子メータ}', POWER.status[0]);
          
          const interval = setInterval(() => {
            console.log('Interval 3');
            getFacilityId = getFacilityId || GetElementById(template_path);
            if (getFacilityId) {
              // const img_blue_arrow = GetElementById('img-green-arrow');
              // if (img_blue_arrow) img_blue_arrow.classList.add("d-none");
              
              const none = GetElementById('充電量_none_子メータ');
              if (none) none.style.color = 'transparent';
              
              clearInterval(interval);
            }
          }, timer);
        }
        
        //total_consumption: 総消費量_kWh}
        const set_total_consumption = total_consumption ? total_consumption.toFixed(1) : (total_consumption === 0 ? 0 : '--');
        html = html.replace('{総消費量_kWh}', set_total_consumption);
        
        // 放電可能量_kW / (充電可能量_kW + 放電可能量_kW),  status: なし, 発電中
        // let chargeable_amount = (bat_02_discharge_able_amount / (bat_02_discharge_able_amount + bat_02_chargeable_amount)) || 0;
        // chargeable_amount = chargeable_amount ? chargeable_amount.toFixed(1) : 0;
        const chargeable_amount = 0;
        html = html.replace('{蓄電量_kWh}', chargeable_amount);
        if (parseInt(power_generation) >= 1) {
          html = html.replace('{蓄電量_status}', POWER.status[1]);
        }
        else {
          html = html.replace('{蓄電量_status}', POWER.status[0]);
          
          // const interval = setInterval(() => {
          //   console.log('Interval 4');
          //   getFacilityId = getFacilityId || GetElementById(template_path);
          //   if (getFacilityId) {
          //     const img_brown_arrow = GetElementById('img-blue-arrow');
          //     if (img_brown_arrow) img_brown_arrow.classList.add("d-none");
          //
          //     clearInterval(interval);
          //   }
          // }, timer);
        }
        
        // 直近の再エネ利用率 ＝（発電量ー売電量）　/ （発電量ー売電量）＋買電量
        // "power_generation": "発電量_kWh}",
        // "amount_of_electricity_sold": "売電量_kWh}",
        // "power_purchase": "買電量_kWh}",
        let percent = Math.ceil(latest_renewable_energy_usage_rate ?? 0);
        if (template_path === '26d0903dma') {
          percent = Math.ceil(current_power_generation_rate ?? 0);
        }
        percent = percent > 100 ? 100 : percent;
        
        const interval = setInterval(() => {
          console.log('Interval 5');
          getFacilityId = getFacilityId || GetElementById(template_path);
          if (getFacilityId) {
            let graph = GetElementById('percent');
            let percent_number = GetElementById('percent-number');
            if (percent_number) percent_number.innerHTML = percent + "%";
            
            if (graph) {
              if (percent) {
                graph.setAttribute('stroke-dasharray', `${percent}, 100`);
              } else {
                graph.classList.add('d-none');
              }
            }
            
            clearInterval(interval);
          }
        }, timer);
        
        //4. 売電量_kWh} = amount_of_electricity_sold
        const set_amount_of_electricity_sold = amount_of_electricity_sold ? amount_of_electricity_sold.toFixed(1) : (amount_of_electricity_sold === 0 ? 0 : '--');
        html = html.replace('{売電量_kWh}', set_amount_of_electricity_sold);
        html = html.replace('{売電量_status}', set_amount_of_electricity_sold >= 1 ? POWER.status[3] : POWER.status[0]);
        
        if (set_amount_of_electricity_sold < 1) {
          
          const interval = setInterval(() => {
            console.log('Interval 6');
            getFacilityId = getFacilityId || GetElementById(template_path);
            if (getFacilityId) {
              const img_brown_arrow = GetElementById('img-blue-arrow');
              if (img_brown_arrow) img_brown_arrow.classList.add("d-none");
              
              const none = GetElementById('売電量_none');
              if (none) none.style.color = 'transparent';
              clearInterval(interval);
            }
          }, timer);
        }
        
        if (facility.ruby === 1) {
          res_layout.data.html[template_path].yes_ruby = html;
        } else {
          res_layout.data.html[template_path].no_ruby = html;
        }
        
        // res_layout.data.css = css;
        // setFacilityLayout(res_layout.data);
      }
      
      
      // Show data chart
      if (res_chart.data) {
        let {facility, html} = res_layout.data;
        html = facility.ruby === 1 ? html.chart.yes_ruby : html.chart.no_ruby;
        html = html.replace(/<head>(?:.|\n|\r)+?<\/head>/m, '');
        
        // "measurement_date": "データ計測_日時",
        html = html.replace('{measurement_date}', set_measurement_date);
        
        // set power_generation_total
        html = html.replace('{power_generation_total}', Math.ceil(set_power_generation_total));
        
        // facility_name
        const facility_name = facility.name || '';
        html = html.replace('{facility_name}', facility_name);
        
        const {data} = res_chart.data;
        
        let power_generations = [];
        let convert_data = {};
        for (let d in data) {
          const power_generation = data[d].power_generation || 0;
          power_generations.push(power_generation);
          
          let hours = d.replaceAll('-', '/');
          hours = new Date(hours);
          hours = hours.getHours() === 0 ? 24 : hours.getHours();
          convert_data[hours] = {power_generation}
        }
        //power max
        const power_max = Math.max(...power_generations);
        //y power max
        const y_power_max = power_max % 5 === 0 ? power_max : ((parseInt(power_max / 5)) * 5 + 5);
        // axis_y height 25vw (span height 1vw);
        const y_height = 25;
        //margin bottom
        const mb = (y_height / ((y_power_max / 5)) - 1).toFixed(2);
        
        let y_data = '<span class="mb-0">0</span>';
        for (let y = 5; y <= y_power_max; y += 5) {
          y_data = `<span style="margin-bottom: ${mb}vw">${y}</span>` + y_data;
        }
        y_data = '<span class="mb-0" style="position: absolute; margin-top: -2vw; margin-left: -2px;">kWh</span>' + y_data;
        
        let x_data = '';
        for (let y = 5; y <= 19; y++) {
          if (convert_data[y] && convert_data[y].power_generation > 0) {
            
            let data_h = (convert_data[y].power_generation * y_height) / y_power_max;
            data_h = data_h > 0 ? (data_h + 0.5).toFixed(2) : data_h;
            
            x_data += `
              <div class="column">
                <div class="show-data" style="height: ${data_h}vw;"></div>
              </div>
            `;
          } else {
            x_data += `
              <div class="column"></div>
            `;
          }
        }
        
        const interval = setInterval(() => {
          console.log('Interval chart');
          getFacilityId = getFacilityId || GetElementById(template_path);
          if (getFacilityId) {
            const yData = GetElementById('y-data');
            const xData = GetElementById('x-data');
            if (yData && xData) {
              yData.innerHTML = y_data;
              xData.innerHTML = x_data;
            }
            clearInterval(interval);
          }
        }, timer);
        
        if (facility.ruby === 1) {
          res_layout.data.html.chart.yes_ruby = html;
        } else {
          res_layout.data.html.chart.no_ruby = html;
        }
      }
      
      setFacilityLayout(res_layout.data);
      
      /**
       * Set style
       */
      // if (getTemplate) {
      //   let css = facility.data.css;
      //   let style = document.createElement('style');
      //   getTemplate.appendChild(style);
      //   style.type = 'text/css';
      //   if (style.styleSheet) {
      //     style.styleSheet.cssText = css;
      //   } else {
      //     style.appendChild(document.createTextNode(css));
      //   }
      //
      //   const getTemplateDate = GetElementById(template_path);
      //   if (getTemplateDate) getTemplateDate.classList.remove('d-none'); //Show template ruby.
      // }
      
    })).catch(err => {
      if (err && err.message === 'Request failed with status code 400') {
        setFacilityData(false);
        setFacilityLayout(false);
      }
      for (let i = 1; i < 10; i++) {
        window.clearInterval(i);
      }
    });
  };
  
  //Set interval reload API, 1s => 1000
  useEffect(() => {
    if(showTemplate === 'template') {
      // Time 3minutes
      setTimeout(() => {
        changeTemplate();
      }, 180000)
    } else if (['chart', 'energy-explain'].includes(showTemplate)) {
      // Time 1minutes
      setTimeout(() => {
        changeTemplate();
      }, 60000)
    }
  }, [showTemplate]);
  
  useEffect(() => {
    reloadTemplate()
  }, [facility_url, template_path]);
  
  //Set interval reload API 10minutes
  useEffect(() => {
    setInterval(() => {
      reloadTemplate()
    }, 600000);
  }, [facility_url, template_path]);

  useEffect(() => {
    const currentDate = new Date();
    const time = (currentDate.getTime() + '').slice(0, 8);
    setCssLayout(`
        <link
            id="template-css" rel="stylesheet" type="text/css"
            href="${root_template_path}/${template_path}/styles.css?${time}"
          />
        <link
            id="chart-css" rel="stylesheet" type="text/css"
            href="${root_template_path}/chart/styles.css?${time}"
          />
        <link
            id="energy-explain-css" rel="stylesheet" type="text/css"
            href="${root_template_path}/energy-explain/styles.css?${time}"
          />
    `);
  }, [template_path]);
  
  if (facilityData === false || facilityLayout === false) {
    return (<div id="home">
      <div className="center">
        <h1>404</h1>
      </div>
    </div>)
  }
  
  if (!facilityData || !facilityLayout) return null;
  
  const {facility, html} = facilityLayout;
  
  return (
    <div id="facility" style={{background: '#ffffff'}} onClick={() => onChangeTemplate()}>
      <div dangerouslySetInnerHTML={{__html: cssLayout}}></div>
      <div id="tab-template" className="animation-fade">
        <div
          id="template"
          dangerouslySetInnerHTML={{__html: facility.ruby ? html[template_path].yes_ruby : html[template_path].no_ruby}}
        />
      </div>
      <div id="tab-chart" className="d-none animation-fade">
        <div
          id="chart"
          dangerouslySetInnerHTML={{__html: facility.ruby ? html.chart.yes_ruby : html.chart.no_ruby}}
        />
      </div>
      <div id="tab-energy-explain" className="d-none animation-fade">
        <div
          id="energy-explain"
          dangerouslySetInnerHTML={{__html: facility.ruby ? html['energy-explain'].yes_ruby : html['energy-explain'].no_ruby}}
        />
      </div>
    </div>
  );
}

export default Template;