import "../assets/scss/home.css"
function Home() {
  return (
    <div id="home">
      <div className="center">
        <h2>Home solarview</h2>
      </div>
    </div>
  )
}
export default Home;