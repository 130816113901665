// v6
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import Template from "./pages/template";
import Template19gcxc0vfu from "./pages/template-19gcxc0vfu";
import Template26d0903dma from "./pages/template-26d0903dma";
import Home from "./pages/home";
import React from "react";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/show/19gcxc0vfu" element={<Template19gcxc0vfu />} />
        <Route path="/show/26d0903dma" element={<Template26d0903dma />} />
        <Route path="/show/:facility_url" element={<Template />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
